/*general*/
:root {
    --color-brand: #186C97;
    --color-brand-dark: #0e3d55;
    --color-brand-gray: #6D6E70;
    --color-gray-light: #EBF1F5;
    --color-white: #FFF;
    --color-blue-additional: #8bd6f7;
}

@keyframes background {
    to {
        background-position-y: 1000%;
    }
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html {
    font-size: 100%;
}

body {
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    color: var(--color-brand-gray);
    background-image: url(../images/background.png);
    background-size: 50%;
    background-position-y: top;
    background-attachment: fixed;
    animation: background 70s infinite linear backwards;
    will-change: background-position-y;
    overflow-x: hidden;
    padding: 0 20px;
}


@media (max-width: 640px) {
    body {
        animation: background 170s infinite linear backwards;
    }
}

figure img {
    width: 100%;
}

figcaption {
    color: #fff;
    font-size: small;
    text-align: left;
}

.stop-scrolling {
    height: 100%;
    overflow: hidden;
}

/*geneneral svg styles*/
path, line, polygon, rect, polyline {
    fill: none;
    stroke: var(--color-brand);
    stroke-width: 1.5;
    stroke-linecap: round;
}

circle {
    fill: var(--color-brand);
}

svg image {
    overflow: visible;
}

/*header*/
header {
    position: fixed;
    top: 0;
    width: calc(100% - 40px);
    height: 50px;
    padding: 15px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 2;
    background: #fff;
}

.header_logo {
    width: 110px;
    height: 30px;
    background: url(../images/logo.svg) no-repeat left center;
    background-size: contain;
}

.header_menu {
    width: 30px;
    height: 23px;
    cursor: pointer;
    transition: transform .3s;
}

.header_menu-open rect {
    fill: var(--color-brand-gray);
    stroke: none;
    transition: fill .3s;
}

.header_menu:hover rect {
    fill: var(--color-brand);
}

/*navigation*/
.menu {
    position: fixed;
    width: 100vw;
    height: 100vh;
    flex-direction: column;
    color: var(--color-white);
    font-size: 40px;
    z-index: 999;
    display: flex;
    right: -100vw;
    transition: all 1s;
    z-index: 2;
    background: #186c97;
    background: -moz-linear-gradient(45deg, #186c97 0%, #2e7396 100%);
    background: -webkit-linear-gradient(45deg, #186c97 0%, #2e7396 100%);
    background: linear-gradient(45deg, #186c97 0%, #2e7396 100%);
    align-items: center;
}

.menu-opened {
    right: 0;
}

.menu-closed {
    right: -100vw;
}

.menu_link {
    height: 20vh;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: var(--color-white);
    text-decoration: none;
    transition: all .3s;
    cursor: pointer;
    line-height: 1.1;
    border-bottom: 2px solid rgba(14, 61, 85, 0.975);
    width: 50%;
}

@media (max-width: 639px) {
    .menu_link {
        font-size: x-large;
        width: calc(100% - 40px);
    }
}

@media (min-width: 639px) and (max-height: 540px) {
    .menu_link {
        font-size: large;
    }
}

.menu_link:hover {
    color: var(--color-brand-dark);
}

.menu_link:last-child {
    border-bottom: none;
}

.menu_close {
    position: absolute;
    right: 20px;
    top: 0;
    width: 25px;
    cursor: pointer;
}

.menu_close rect {
    fill: #fff;
    stroke: none;
}

.menu_close:hover rect {
    fill: var(--color-brand-dark);
}

/*main container*/
.main-container {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    flex-direction: column;
    max-width: 1200px;
    margin: 0 auto;
}

@media (max-width: 1200px) {
    .main-container {
        margin: 0;
    }
}

/*screens*/
.screen {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    position: relative;
    padding: 10vh 0;
}

@media (max-width: 639px) {
    .screen:not(:first-of-type) {
        height: auto;
    }
}

.screen_header {
    color: var(--color-brand); /*h1*/
    font-size: 7rem;
    line-height: .9;
    margin-bottom: 30px;
}

.screen_subheader {
    font-size: 2.3rem; /*h2*/
    position: relative;
}

.screen_subheader--updated {
    position: relative;
    display: inline-block;
}

.screen_subheader--updated:after {
    content: 'new';
    width: 24px;
    height: 20px;
    position: absolute;
    right: -26px;
    top: 4px;
    color: #c3262e;
    font-weight: bold;
    font-size: 13px;
}


.screen_subheader-info { /*h3*/
    font-size: 1.2rem;
    transform: translateY(-50px);
    opacity: 0;
}

.screen_subheader-info a {
    color: var(--color-brand-gray);
    text-decoration: none;
}

@media (max-width: 639px) {
    .screen_header {
        font-size: 3.5rem;
    }

    .screen_subheader {
        font-size: large;
    }

    .screen_subheader-info {
        font-size: smaller;
    }
}

.screen_subheader-info-scrolled {
    animation: scroll .6s linear forwards;
}

.screen_image {
    display: flex;
    width: 100%;
    justify-content: center;
    position: relative;
    padding-top: 5vh;
    height: 45vh;
}

.screen_image a {
    display: flex;
    transition: all .5s;
    justify-content: center;
}

.screen_image a:hover svg {
    transform: scale(1.1);
}

.screen_image svg {
    width: auto;
    max-width: calc(100% - 40px);
    height: 45vh;
    transition: all .5s;
    transform-origin: center;
}

@keyframes background-web {
    15%, 85% {
        transform: matrix(0.1291, 0, 0, 0.1291, 6.8012, 6.5544);
    }
    45%, 55% {
        transform: matrix(0.1291, 0, 0, 0.1291, 6.3228, -151.7521);
    }
}

@keyframes preload {
    to {
        background-size: 0px;
    }
}

/* preloader*/
.preloader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 150px;
    background-color: white;
    background-image: url(../images/logo-preloader.svg);
    animation: preload .5s linear forwards;
    z-index: 2000;
}

.screen_image-web-light {
    fill: var(--color-white);
    opacity: .2;
    stroke: none;
}
